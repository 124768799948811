<template>
  <div class="container" :class="{ 'fixed-position': addCreditCardActive }">
    <bill-list></bill-list>
    <!--        <h1 class="payment-methods-title">其他付款方式</h1>-->
    <other-payment-methods
      :clinicId="caseData.clinicId"
      v-model="selectedPayment"
      @input="cancelCreditCard"
    ></other-payment-methods>
    <!--        <section class="credit-card-container">-->
    <!--            <div class="title">-->
    <!--                <h1>信用卡</h1>-->
    <!--                <span @click="addCreditCardActive = true">新增</span>-->
    <!--            </div>-->
    <!--            <div class="content">-->
    <!--                <el-radio-->
    <!--                    v-model="selectedCreditCard"-->
    <!--                    @input="cancelOtherPayment"-->
    <!--                    label="1"-->
    <!--                >-->
    <!--                    <div>Lam xxx xxx</div>-->
    <!--                    <div>0000-0000-0000-0000</div>-->
    <!--                </el-radio>-->
    <!--            </div>-->
    <!--        </section>-->
    <payment-agreement></payment-agreement>
    <section class="notes">
      <!-- <p>
                {{ paymentDeliveryInstructions }}
            </p> -->
      <p>
        {{ paymentDisclaimer }}
      </p>
    </section>
    <div class="reservation-btn-group">
      <button @click="back">返回</button>
      <button @click="validate">確認</button>
    </div>
    <div class="add-credit-card-page" :class="{ active: addCreditCardActive }">
      <div class="header">
        <div @click="addCreditCardActive = false">
          <i class="icon-leftarrow"></i>
          返回
        </div>
      </div>
      <div class="transaction-info">
        <div class="info">
          <div class="title">商戶：</div>
          <div class="content">iMeddy</div>
        </div>
        <div class="info">
          <div class="title">交易金額：</div>
          <div class="content">$ {{ billData.amount }}</div>
        </div>
      </div>
      <div class="credit-card-info">
        <div class="title">持卡人姓名</div>
        <el-input placeholder="請輸入持卡人姓名"></el-input>
        <div class="title">
          信用卡號碼
          <div class="icon-container">
            <img src="../../../assets/img/unionpay.png" alt="" />
            <img src="../../../assets/img/masterCard.png" alt="" />
            <img src="../../../assets/img/visa.png" alt="" />
          </div>
        </div>
        <el-input placeholder="請輸入卡數"></el-input>
        <div class="title">信用卡到期日</div>
        <div class="expired-date-container">
          <el-input></el-input>
          <div class="divider">/</div>
          <el-input></el-input>
        </div>
        <div class="title">信用卡安全碼</div>
        <el-input placeholder="請輸入信用卡安全碼"></el-input>
      </div>
      <button @click="addCreditCardActive = false" class="rounded-primary">
        付款
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { OrdersPayment } from "@/api";
import OtherPaymentMethods from "../../../components/otherPaymentMethods.vue";
import PaymentAgreement from "../../../components/paymentAgreement.vue";
import billList from "../caseDetailComponents/billList.vue";
export default {
  components: { billList, PaymentAgreement, OtherPaymentMethods },
  data: function () {
    return {
      addCreditCardActive: false,
      selectedPayment: { type: -1, id: -1 },
      selectedCreditCard: -1,
    };
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseDetail.caseData,
      billData: (state) => state.caseDetail.billData,
      paymentDisclaimer: (state) => state.sysConfig.paymentDisclaimer,
      paymentDeliveryInstructions: (state) =>
        state.sysConfig.paymentDeliveryInstructions,
    }),
  },
  methods: {
    cancelCreditCard() {
      this.selectedCreditCard = -1;
    },
    back() {
      this.$router.go(-1);
    },
    cancelOtherPayment() {
      this.selectedPayment = { type: -1, id: -1 };
    },
    validate() {
      if (this.selectedPayment.id === -1) {
        this.$message({
          type: "error",
          message: "請選擇支付方式",
        });
        return false;
      }
      let data = {
        order_number: this.billData.orderNumber,
        amount: this.billData.amount,
        payment_type: this.selectedPayment.id,
      };
      OrdersPayment(data)
        .then((res) => {
          const loading = this.$loading({
            lock: true,
            text: "Loading....",
            spinner: "el-icon-loading",
            // background: "rgba(0, 0, 0, 0.7)",
          });
          window.location = res.data.bank_transaction_url;
          // 目前先全部使用打开新网页的方式，之后如果有不同操作再分情况分类
          // if (this.selectedPayment.type === 1) {
          //     window.location = res.data.bank_transaction_url;
          // } else if (this.selectedPayment.type === 3) {
          //     window.location = res.data.bank_transaction_url;
          // } else if (this.selectedPayment.type === 5) {
          //     window.location = res.data.bank_transaction_url;
          // }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding-bottom: 60px;
  section:not([class~="bill-container"]):not(:last-of-type) {
    border-bottom: 1px solid $black;
  }
  h1.payment-methods-title {
    font-size: 21px;
    padding: 16px 16px 0;
    margin: 0;
    background-color: white;
  }
  .credit-card-container {
    background-color: white;
    @include flexc-start-stretch;
    .title {
      @include flexr-between-center;
      padding: 16px;
      h1 {
        margin: 0;
        font-size: 21px;
      }
      span {
        text-decoration: underline;
      }
    }
    .content {
      padding: 0 16px 16px;
    }
  }
  .notes {
    background-color: white;
    border-bottom: none;
    padding: 16px;
    font-size: 12px;
  }
}
.add-credit-card-page {
  @include open-from-right;
  z-index: 600;
  overflow: auto;
  background-color: white;
  .header {
    font-size: 24px;
    padding: 16px;
  }
  .rounded-primary {
    width: 60%;
    margin: 0 auto 16px;
  }
}
.transaction-info {
  padding: 16px 16px 0;
  background-color: $green-300;
  .info {
    padding-bottom: 16px;
    color: white;
    .title {
      font-size: 21px;
      font-weight: $semi-bold;
    }
    .content {
      font-size: 16px;
      font-weight: $regular;
    }
  }
}
.credit-card-info {
  padding: 16px;
  .title {
    font-size: 21px;
    font-weight: $semi-bold;
    @include flexr-between-center;
    img {
      height: 28px;
    }
  }
  .el-input::v-deep {
    .el-input__inner,
    .el-input__inner:hover,
    .el-input__inner:focus {
      border: none;
      border-bottom: 1px solid $black;
      border-radius: 0;
      font-size: 16px;
      padding-left: 0;
    }
  }
}
.expired-date-container {
  @include flexr-start-center;
  padding: 8px 0;
  .divider {
    margin: 0 16px;
  }
  .el-input::v-deep {
    width: 20%;
    margin: 0;
    .el-input__inner,
    .el-input__inner:hover,
    .el-input__inner:focus {
      padding: 0;
      border: 1px solid $black;
      border-radius: 0;
      font-size: 16px;
      padding-left: 0;
    }
  }
}
.active {
  transform: scaleX(1);
}
.fixed-position {
  height: calc(100vh - 96px - (100vw - 80px) / 3);
  overflow: hidden;
}
</style>