<template>
  <section class="payment-methods-container">
    <span class="payfor-type">支付方式：</span>
    <div
      class="method"
      @click="selectPayment(payment.type, payment.id)"
      v-for="payment in paymentTypeList"
      :key="payment.type"
    >
      <el-radio style="margin-top: 10px" v-model="payment.id" />
      <img :src="payment.image" class="payfor-img" />
      {{ payment.title }}
    </div>
    <div class="placeholder"></div>
  </section>
</template>

<script>
import { paymentTypes } from "@/api";
export default {
  data: function () {
    return {
      paymentTypeList: [],
      localSelectedPayment: this.value,
    };
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    selectPayment(type, id) {
      this.$emit("input", { type, id });
    },
    updatePaymentMethods() {
      let data = {
        clinic_id: this.clinicId,
        type: 2,
        status: 1,
      };
      paymentTypes(data).then((res) => {
        this.paymentTypeList = [];
        res.data.dataList.forEach((e) => {
          this.paymentTypeList.push(e);
        });
      });
    },
  },
  watch: {
    clinicId: function () {
      this.updatePaymentMethods();
      this.$emit("input", { type: -1, id: -1 });
    },
    value: function () {
      this.localSelectedPayment = this.value.id;
    },
  },
  mounted: function () {
    this.updatePaymentMethods();
  },
};
</script>

<style lang="scss" scoped>
.payfor-img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.payfor-type {
  height: auto;
  margin-left: 16px;
  font-size: 18px;
}
.payment-methods-container {
  background-color: white;
  padding-top: 10px;
  // padding: 16px;

  // align-items: center;
  // justify-content: center;
  // display: flex;
  // flex-direction: row;
  // @include flexr-between-start;
  flex-wrap: wrap;
  .method {
    // background-color: #def0ec;
    padding-left: 16px;
    width: 100%;
    height: 60px;
    align-items: center;
    // justify-content: center;
    display: flex;
    flex-direction: row;
    // @include flexr-between-start;
    // padding: 0 16px;
    // @include flexr-center-center;
    // margin: 10px;
    // border-radius: 15px;
  }
  .placeholder {
    height: 0;
  }
  .selected {
    background-color: $green-300;
    color: $white;
  }
}
</style>